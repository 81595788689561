import axiosInstance from './utils';
import { itemStatusValues } from '../model/itemStatus';
import { badgeUserFieldStartTs, badgeUserFieldStopTs } from '../model/badgeuser';

export default {
  name: 'badgesusers',

  create(item) {
    const clean = this.setDefaultValues(item);
    return axiosInstance.post(`/${this.name}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  delete(itemId) {
    return axiosInstance.delete(`/${this.name}/${encodeURIComponent(itemId)}`);
  },

  getList(getAll = false) {
    /* Not the usual getAll mechanism.
     * We always get the inactive items (to retrieve the unclosed items,
     *    even if badge/user/company  is inactive)
     * If getAll is not set to true, we get the "current" items (according to validity period)
     */
    const now = Math.floor(Date.now() / 1000);
    let url = `/${this.name}?${itemStatusValues.getAllUrlParam}`;
    if (getAll !== true) {
      url += `&${badgeUserFieldStartTs.name}=${now}&${badgeUserFieldStopTs.name}=${now}`;
    }
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  setDefaultValues(item) {
    const result = { ...item };
    if (item.badgeIsActive === null) result.badgeIsActive = true;
    if (item.companyIsActive === null) result.companyIsActive = true;
    if (item.userIsActive === null) result.userIsActive = true;
    if (item.itemStatus === null) result.itemStatus = itemStatusValues.default;
    return result;
  },

  update(itemId, item) {
    const clean = this.setDefaultValues(item);
    delete clean.id;
    delete clean.itemStatus;
    delete clean.creationTs;
    delete clean.lastUpdateTs;
    return axiosInstance.put(`/${this.name}/${encodeURIComponent(itemId)}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },
};
