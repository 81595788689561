/**
 * Badge- User relationship schema
 */

import { roleAdmin, roleSuperAdmin } from '@/model/accessRolesModel';
import rules from './validationRules';
import i18n from '../i18n';
import { getItemStatusTypeReferences } from './itemStatus';
import { datatypes, getBooleanTypeReferences } from './datatypes';

const badgeUserFieldId = {
  name: 'id',
  type: datatypes.string,
  description: 'Unique id of the badgeUser item',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const badgeUserFieldBadgeId = {
  name: 'badgeId',
  type: datatypes.string,
  description: 'Unique id of the badge',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const badgeUserFieldUserId = {
  name: 'userId',
  type: datatypes.string,
  description: 'Unique id of the user',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const badgeUserFieldStartTs = {
  name: 'startTs',
  type: datatypes.timestamp,
  description: 'Start timestamp of the relationship.',
  label: i18n.tc('badgeuser.fields.startTs', 1),
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const badgeUserFieldStopTs = {
  name: 'stopTs',
  type: datatypes.timestamp,
  description: 'Stop timestamp of the relationship.',
  label: i18n.tc('badgeuser.fields.stopTs', 1),
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const badgeUserFieldMacAddress = {
  name: 'macAddress',
  type: datatypes.string,
  description: 'macAddress of the badge',
  minLength: 1,
  maxLength: 64,
  label: i18n.tc('badgeuser.fields.macAddress', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldUuid = {
  name: 'uuid',
  type: datatypes.string,
  description: 'UUID of the badge',
  minLength: 1,
  maxLength: 64,
  label: i18n.tc('badgeuser.fields.uuid', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldRfidTagId = {
  name: 'rfidTagId',
  type: datatypes.string,
  description: 'RFID tag ID of the badge.',
  minLength: 0,
  maxLength: 128,
  label: i18n.tc('badgeuser.fields.rfidTagId', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldBadgeIsActive = {
  name: 'badgeIsActive',
  type: datatypes.boolean,
  description: 'Activation status of the badge.',
  label: i18n.tc('badgeuser.fields.badgeIsActive', 1),
  references: getBooleanTypeReferences(),
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldEmail = {
  name: 'email',
  type: datatypes.string,
  description: 'email of the user',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('badgeuser.fields.email', 1),
  rules: [rules.required, rules.email],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldFirstname = {
  name: 'firstname',
  type: datatypes.string,
  description: 'firstname of the user',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('badgeuser.fields.firstname', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldLastname = {
  name: 'lastname',
  type: datatypes.string,
  description: 'lastname of the user',
  minLength: 0,
  maxLength: 128,
  label: i18n.tc('badgeuser.fields.lastname', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldUserIsActive = {
  name: 'userIsActive',
  type: datatypes.boolean,
  description: 'Activation status of the badge.',
  label: i18n.tc('badgeuser.fields.userIsActive', 1),
  references: getBooleanTypeReferences(),
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldCompanyId = {
  name: 'companyId',
  type: datatypes.string,
  description: 'Id of the company of the user.',
  label: '',
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldCompanyName = {
  name: 'companyName',
  type: datatypes.string,
  description: 'Name of the company the user is associated to.',
  minLength: 1,
  maxLength: 96,
  label: i18n.tc('badgeuser.fields.companyName', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldCompanyIsActive = {
  name: 'companyIsActive',
  type: datatypes.boolean,
  description: 'Activation status of the company.',
  label: i18n.tc('badgeuser.fields.companyIsActive', 1),
  references: getBooleanTypeReferences(),
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeUserFieldItemStatus = {
  name: 'itemStatus',
  type: datatypes.itemStatus,
  description: 'Etat de l élément.',
  label: i18n.tc('badgeuser.fields.itemStatus', 1),
  references: getItemStatusTypeReferences(),
  rules: [],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
};

const badgeUser = {
  fields: [
    badgeUserFieldId,
    badgeUserFieldBadgeId,
    badgeUserFieldUserId,
    badgeUserFieldStartTs,
    badgeUserFieldStopTs,
    badgeUserFieldBadgeIsActive,
    badgeUserFieldMacAddress,
    badgeUserFieldUuid,
    badgeUserFieldRfidTagId,
    badgeUserFieldCompanyId,
    badgeUserFieldCompanyName,
    badgeUserFieldCompanyIsActive,
    badgeUserFieldEmail,
    badgeUserFieldFirstname,
    badgeUserFieldLastname,
    badgeUserFieldUserIsActive,
    badgeUserFieldItemStatus,
  ],
  crudAllowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    readInactive: [roleSuperAdmin],
    create: [roleAdmin, roleSuperAdmin],
    update: [], // As long as we do not provide badgeuser validity perdiod, update is not available
    delete: [roleSuperAdmin],
  },
};

export default badgeUser;
export {
  badgeUser,
  badgeUserFieldId,
  badgeUserFieldBadgeId,
  badgeUserFieldUserId,
  badgeUserFieldStartTs,
  badgeUserFieldStopTs,
  badgeUserFieldBadgeIsActive,
  badgeUserFieldMacAddress,
  badgeUserFieldUuid,
  badgeUserFieldRfidTagId,
  badgeUserFieldCompanyId,
  badgeUserFieldCompanyName,
  badgeUserFieldCompanyIsActive,
  badgeUserFieldEmail,
  badgeUserFieldFirstname,
  badgeUserFieldLastname,
  badgeUserFieldUserIsActive,
  badgeUserFieldItemStatus,
};
